import React from "react";
import styles from './YouTubeScreen.module.scss';
import zveroboy  from '../../shared/assets/image/zveroboyBigLogo.svg';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css';

export const YouTubeScreen = () => {

  return (
    <div id={'nav4'} className={`${styles.YouTubeScreen} animated`}>
      <div className={styles.YouTubeScreen__infoTextWrapper}>
        <div className={styles.YouTubeScreen__textHeader}>
          <p className={styles.YouTubeScreen__headerText}>Больше о  группе</p>
          <img className={styles.YouTubeScreen__headerLogo} src={zveroboy} alt={"Зверобой"}/>
        </div>
          <p className={styles.YouTubeScreen__infoText}>
            Московская группа «Зверобой» даёт рок с 2008 года, иногда — с элементами блюз-рока, гранджа, фолка и других стилей. В числе боевых хитов —  «Еду-едут БТРы», «Благородный дон», «Моя родина возвращается», «Мой Мариуполь» и «Наша победа».
            <br/>
            <br/>
            На счету коллектива — пять студийных альбомов и один концертный, регулярные выступления на площадках Москвы и Санкт-Петербурга, большие рок-фестивали и телеэфиры.
            <br/>
            <br/>
            Участники группы занимают активную гражданскую позицию и с 2015 года регулярно бывают в Донбассе, выступая как на центральных площадях, так и на передовой линии перед бойцами. Песни «Зверобоя» поют в окопах.
          </p>
      </div>
      <div className={styles.YouTubeScreen__youtubeWrapper}>
        <div className={styles.YouTubeScreen__swipperWrapper}>
          <Swiper
            cssMode={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination]}
            className={styles.YouTubeScreen__swipper}
          >
            <SwiperSlide data-hash="slide1">
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/y25dK6rytZM?si=WoRoqUNi4HBbatZ5"
                      title="YouTube video player" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen/>
            </SwiperSlide>
            <SwiperSlide data-hash="slide2">
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/zYJKEG16hSg?si=zQnimYNZBYAJyQf8"
                      title="YouTube video player" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen/>
            </SwiperSlide>
            <SwiperSlide data-hash="slide3">
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/H8tdljrda80?si=OfS597DXIsFo7dkc"
                      title="YouTube video player" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen/>
            </SwiperSlide>
            <SwiperSlide data-hash="slide4">
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/pJ8XXQHl8bU?si=CQrmdq5n-sUzlPj8"
                      title="YouTube video player" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen/>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className={styles.YouTubeScreen__buttonWrapper}>
          <a target={'_blank'} href={'https://music.apple.com/ru/artist/%D0%B7%D0%B2%D0%B5%D1%80%D0%BE%D0%B1%D0%BE%D0%B9/1478441716'}>
            <button>
              <div className={cn(styles.YouTubeScreen__button, styles.YouTubeScreen__button_apple)} />
            </button>
          </a>
          <a target={'_blank'} href={'https://music.yandex.ru/artist/218069/'}>
            <button>
              <div className={cn(styles.YouTubeScreen__button, styles.YouTubeScreen__button_yandex)} />
            </button>
          </a>
          <a target={'_blank'} href={'https://music.youtube.com/channel/UCq7dvQC9arfGaNF65e_-wmg'}>
            <button>
              <div className={cn(styles.YouTubeScreen__button, styles.YouTubeScreen__button_youtube)} />
            </button>
          </a>
          <a target={'_blank'} href={'https://zvuk.com/artist/35297413'}>
            <button>
              <div className={cn(styles.YouTubeScreen__button, styles.YouTubeScreen__button_zvuk)} />
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};
