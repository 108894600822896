import styles from './App.scss';
import {ArtistsScreen} from "./screens/ArtistsScreen/ArtistsScreen";
import {PerformanceScreen} from "./screens/PerformanceScreen/PerformanceScreen";
import {MainScreen} from "./screens/MainScreen/MainScreen"
import {MapScreen} from "./screens/MapScreen/MapScreen"
import {YouTubeScreen} from "./screens/YouTubeScreen/YouTubeScreen";
import { FooterScreen } from './screens/FooterScreen/FooterScreen';
import { BigBuyButton } from "./screens/BigBuyButton/BigBuyButton";
import { useEffect, useState} from 'react';

function App() {

  let [animItems] = useState([])
  const [query, setQuery] = useState([]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setQuery(Object.entries(params).map((item) =>{
      return `${item[0]}=${item[1]}`
    }).join('&'))
  }, [])

  console.log(query);

  const onScroll = () => {
    animItems.forEach((item) => {
      const itemHeight = item.offsetHeight
      const itemOffset = getOffset(item).top
      const itemStart = 4

      let itemPoint = window.innerHeight - itemHeight / itemStart

      if (itemHeight > window.innerHeight) {
        itemPoint = window.innerHeight - window.innerHeight / itemStart
      }

      const pageYOffset = window.pageYOffset || document.documentElement.scrollTop
      if ((pageYOffset > itemOffset - itemPoint) && pageYOffset < (itemOffset + itemHeight)) {
        item.classList.add('_active')
      }
    })
  }

  const getOffset = (el) => {
    const rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }

  useEffect(() => {
    const elements = document.querySelectorAll('.animated')
    if (elements.length > 0) {

      animItems = elements

      window.addEventListener('scroll', onScroll)

      setTimeout(() => onScroll(), 500)
    }
  }, [])
  return (
    <div className={styles.App}>
      <MainScreen query={query} />
      <PerformanceScreen query={query} />
      <ArtistsScreen />
      <MapScreen query={query} />
      <YouTubeScreen />
      <BigBuyButton query={query} />
      <FooterScreen query={query} />
    </div>
  );
}

export default App;
