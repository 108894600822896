import styles from "./BigBuyButton.module.scss";
import {ReactComponent as Ticket } from '../../shared/assets/image/ticket.svg'

export const BigBuyButton = ({query}) => {

  return (
    <a className={styles.button__link} target={'_blank'} href={`https://iframeab-pre3794.intickets.ru/seance/13952077/?${query}`}>
      {/*global _tmr */}
      <button onClick={() => _tmr.push({ type: 'reachGoal', id: 3400462, value: 1, goal: 'lead'})} value="Заказать" className={styles.button}>
        <p className={styles.button__text}>Купить билет на спектакль</p>
        <Ticket className={styles.button__img}/>
      </button>
    </a>
  );
}
