import React, { useEffect } from "react";
import styles from "./MapScreen.module.scss";
import cn from "classnames";

export const MapScreen = ({query}) => {

  useEffect(() => {
    window.ymaps?.ready(() => {
      const map = new window.ymaps.Map('map', {
        center: [55.782795, 37.613808],
        zoom: 15
      })
      const placemark = new window.ymaps.Placemark(map.getCenter(), {}, {
        iconLayout: 'default#image',
        iconImageHref: './img/Pin.svg'
      })
      map.geoObjects.add(placemark)
    })
  }, [])

  return <div id={'nav3'} className={`${styles.MapScreen} animated`}>
    <div className={styles.MapScreen__Content}>
      <div>
        <h3>До встречи <span>18 ноября 18:00</span> в <span>Театре Российской Армии</span></h3>
      </div>
      <div className={styles.MapScreen__InfoWrapper}>
        <div className={styles.MapScreen__Info}>
          <div className={styles.MapScreen__InfoItem}>
            <img src="./img/Pin.svg" alt="Pin" />
            <p className={styles.MapScreen__Address}><span>Москва,</span> Суворовская площадь, д.2</p>
          </div>
          <div className={styles.MapScreen__InfoItem}>
            <img src="./img/metro.svg" alt="Metro" />
            <p>Достоевская</p>
          </div>
          <a rel="noreferrer" className={styles.MapScreen__yandexNav} target={'_blank'} href="https://yandex.ru/maps/?rtext=~55.782795,37.613808">
            <div className={cn(styles.MapScreen__InfoItem)}>
              <img src="./img/ya.svg" alt="Map" />
              <p>Как доехать</p>
            </div>
          </a>
        </div>
        <div className={cn(styles.MapScreen__InfoItem, styles.MapScreen__InfoItem__btn)}>
          <a rel="noreferrer" target={'_blank'} className={ styles.MapScreen__btnLink} href={`https://iframeab-pre3794.intickets.ru/seance/13952077/?${query}`}>
            {/*global _tmr */}
            <button onClick={() => _tmr.push({ type: 'reachGoal', id: 3400462, value: 1, goal: 'lead'})} value="Заказать" className={ styles.MapScreen__btnBuy}>
              Купить билет
            </button>
          </a>
        </div>
      </div>
    </div>
    <div className={styles.MapScreen__Map}>
      <div>
        <div className={styles.MapScreen__MapImages}>
          <img className={styles.MapScreen__MapPapper} src="./img/papper.png" alt="Papper" />
          <img className={styles.MapScreen__MapTape} src="./img/tape.png" alt="Tape" />
        </div>
        <div className={styles.MapScreen__MapIframe}>
          <div className={styles.MapScreen__MapContent} id="map"></div>
          {/* <iframe className={styles.MapScreen__MapContent} src="https://yandex.ru/map-widget/v1/?um=constructor%3A36ba34259e6dbd211c4c38641e27435433d28523a109ab52b09c2085c09f5c87&amp;source=constructor" frameborder="0" title="map"></iframe> */}
        </div>
        </div>
    </div>
  </div>
};
