import React, {useEffect, useState} from "react";
import styles from './ArtistsListDesktop.module.scss';
import cn from 'classnames';
import { AccordionItem, ControlledAccordion, useAccordionProvider} from '@szhsin/react-accordion';
import {scrollToElm} from "../../../shared/scripts";

export const ArtistsListDesktop = ({onChange, list}) => {
  const [itemId, setItemId] = useState('ZveroboyBand');

  const providerValue = useAccordionProvider({
    transition: true,
    transitionTimeout: 250
  });

  function scrollElement(id){
    setTimeout(() => {
      const container = document.getElementById('div');
      const target = document.getElementById(id);
      scrollToElm(container, target, 0.1)
    }, 0);
  }

  const onClick = (id) => {
    setItemId(id);
    onChange(id);
    setTimeout(() => scrollElement(id), 130)
  }

  return (
    <div id={'div'} className={styles.ArtistsList}>
    <ControlledAccordion providerValue={providerValue}>
      {list.map((item, index) => {
        return (
          <div id={item.id}>
          <AccordionItem initialEntered={itemId === item.id} itemKey={`${item.id}-${index + 1}`} key={item.id} header={
            <div onClick={() => onClick(item.id, `:r${index}:`)} className={styles.ArtistsList__nameWrapper} key={`atrListDesktop_wrapper_${item.id}`}>
              <p className={cn(styles.ArtistsList__name, item.id === itemId && styles.ArtistsList__name__onActive)}>{item.name}</p>
              <p className={cn(styles.ArtistsList__subName, item.id === itemId && styles.ArtistsList__subName__onActive)}>{item.subName}</p>
            </div>
          }>
            <div className={cn(styles.ArtistsList__wrapper)}>
              <div dangerouslySetInnerHTML={{__html: item.description}} className={styles.ArtistsList__description}/>
              <div className={styles.ArtistsList__textWrapper}>
                {item.text.map( (textItem, index) => (
                  <div className={styles.ArtistsList__textItemWrapper} key={`textItem-${item.id}-${index}`}>
                    <div style={{height: '50%'}} dangerouslySetInnerHTML={{__html: textItem}} className={styles.ArtistsList__textItem}/>
                  </div>
                ))}
              </div>
            </div>
          </AccordionItem>
          </div>
        )
      })}
    </ControlledAccordion>
    </div>
  );
};
