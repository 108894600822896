import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

var bingo = 0;

function isVisible(elem) {
  var coords = elem?.getBoundingClientRect();
  var windowHeight = document.documentElement.clientHeight;
  var topVisible = coords?.top > 0 && coords?.top < windowHeight;
  var bottomVisible = coords?.bottom < windowHeight && coords?.bottom > 0;
  return topVisible || bottomVisible;
}

function showVisible() {
  var block = document.getElementById("bottom");
  if (isVisible(block)) {
    if(bingo == 0){
      /*global ym */
      ym(95020777,'reachGoal','scroll_to_bottom')
      bingo = 1;
    }
  }
}

if(bingo === 0){
  window.onscroll = showVisible;
  showVisible();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
