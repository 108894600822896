import { useEffect, useRef, useState } from 'react';

const tabletMatch = window.matchMedia('(max-width: 1023px)');
const mobileMatch = window.matchMedia('(max-width: 767px)');

const getState = () => ({
  isTablet: tabletMatch.matches,
  isMobile: mobileMatch.matches,
});

const useMq = () => {
  const timeout = useRef(0);
  const [data, setData] = useState(getState);

  useEffect(() => {
    const onResize = () => {
      clearTimeout(timeout.current);
      timeout.current = window.setTimeout(() => {
        setData((prev) => {
          const value = getState();
          if (value.isTablet !== prev.isTablet || value.isMobile !== prev.isMobile) return value;
          return prev;
        });
      }, 150);
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return data;
};

export default useMq;
