import React, {useState} from "react";
import styles from "./ArtistsScreen.module.scss";
import { ArtistsPhoto } from "./ArtistsFoto/ArtistsFoto";
import { ArtistsListDesktop } from './ArtistsListDesktop/ArtistsListDesktop';
import useMq from "../../shared/hooks/useMq";
import {ArtistsListMobile} from "./ArtistsListMobile/ArtistsListMobile";
import Sholoh from "../../shared/assets/image/artists/Sholoh.png";
import Malenko from "../../shared/assets/image/artists/Malenko.png";
import Shevchenko from "../../shared/assets/image/artists/Shevchenko.png";
import Sorokin from "../../shared/assets/image/artists/Sorokin.png";
import Zaslavskaya from "../../shared/assets/image/artists/Zaslavskaya.png";
import ZveroboyBand1B from "../../shared/assets/image/artists/zveroboyBand/zveroboyBandB1.png";
import ZveroboyBand1W from "../../shared/assets/image/artists/zveroboyBand/zveroboyBandW1.png";
import ZveroboyBand2B from "../../shared/assets/image/artists/zveroboyBand/zveroboyBandB2.png";
import ZveroboyBand2W from "../../shared/assets/image/artists/zveroboyBand/zveroboyBandW2.png";
import SholohTape from "../../shared/assets/image/artists/tapes/sholohTape.png"
import MalenkoTape from "../../shared/assets/image/artists/tapes/malenkoTape.png"
import ShevchenkoTape from "../../shared/assets/image/artists/tapes/shevchenkoTape.png"
import SorokinTape from "../../shared/assets/image/artists/tapes/sorokinTape.png"
import ZaslavskayaTape from "../../shared/assets/image/artists/tapes/zaslavskayaTape.png"

const pages = [
  {
    imageSrc: Sholoh,
    id: 'Sholoh',
    tape: SholohTape,
    position: {position: "absolute", top: "8.5vw", left: "-5.5vw", height: '12.3vw'},
    birdPosition: {
      a: {top: '39.375vw', left: '1.8vw'},
      b: {top: '11.5vw', right: '3.6vw'},
      c: {right: '2.5vw', top: '14.6vw'},
    }
  },
  {
    imageSrc: Malenko,
    id: 'Malenko',
    tape: MalenkoTape,
    position: {position: "absolute", bottom: "-4vw", left: "-4vw"},
    birdPosition: {
      a: {top: '19.5vw', left: '1vw'},
      b: {top: '48vw', right: '6.25vw'},
      c: {right: '2.6vw', top: '14.4vw'},
    }
  },
  {
    imageSrc: Shevchenko,
    id: 'Shevchenko',
    tape: ShevchenkoTape,
    position: {position: "absolute", top: "-3vw", right: "-5vw"},
    birdPosition: {
      a: {top: '3.6vw', left: '1.7vw'},
      b: {top: '22.8vw', right: '3.3vw'},
      c: {right: '24.5vw', top: '1.7vw'},
    }
  },
  {
    imageSrc: Sorokin,
    id: 'Sorokin',
    tape: SorokinTape,
    position: {position: "absolute", bottom: '-1vw', right: '-4vw'},
    birdPosition: {
      a: {top: '1vw', left: '3.3vw'},
      b: {top: '4vw', right: '44.4vw'},
      c: {right: '3.3vw', top: '33.6vw'},
    }
  },
  {
    imageSrc: Zaslavskaya,
    id: 'Zaslavskaya',
    tape: ZaslavskayaTape,
    position: {position: "absolute", bottom: "-2vw", left: "-2vw"},
    birdPosition: {
      a: {top: '1.7vw', left: '28.7vw'},
      b: {top: '2.6vw', right: '3.28vw'},
      c: {right: '2.6vw', top: '15.4vw'},
    }
  },
  {
    imageSrc: [ ZveroboyBand2W, ZveroboyBand2B, ZveroboyBand1W, ZveroboyBand1B ],
    id: 'ZveroboyBand',
    birdPosition: {
      a: {left: '1vw'},
      b: {top: '2.6vw', right: '3.3vw'},
      c: {right: '2.6vw', top: '26vw'},
    }
  }
]

const list = [
  {
    isOpen: true,
    name: 'Зверобой',
    subName: 'Рок-группа',
    id: 'ZveroboyBand',
    description: 'Московская группа «Зверобой» даёт рок с 2008 года. Участники группы занимают активную гражданскую позицию и с 2015 года регулярно бывают на Донбассе, выступая как на центральных площадях, так и на передовой линии перед бойцами. Песни «Зверобоя» поют в окопах.',
    text: ['Мы закончим эту войну — нашей Победой!<br/>' +
    'Мы развеем Вечным огнём злую чёрную тьму!<br/>' +
    'Тот, кто ящик Пандоры открыл, пожалеет об этом!<br/>' +
    'Мы придём навсегда, победим,<br/>' +
    'И закончим войну!<br/>'],
  },
  {
    isOpen: false,
    name: 'Елена Заславская',
    subName: 'Поэтесса',
    id: 'Zaslavskaya',
    description: 'Поэтесса, член Союза писателей России, преподаватель Академии Матусовского, одна из авторов сборника «Поэзия русского лета», «Великий блокпост».',
    text: [
      'И плыли в воздухе сыром<br/>' +
      'Слепые мотыльки метели… <br/>' +
      'Стояли двое у метро<br/>' +
      'И попрощаться не умели.<br/>' +
      'Суровым был его маршрут —<br/>' +
      'Бахмут и рота штурмовая…<br/>',
      //
      'И эти несколько минут<br/>' +
      'Стояли рук не размыкая.<br/>' +
      'Пусть этот миг, как оберег,<br/>' +
      'Его хранит в пылу сражений,<br/>' +
      'И русский Бог, и русский снег,<br/>' +
      'И губ её благословенье.<br/>'
    ],
  },
  {
    isOpen: false,
    name: 'Влад Маленко',
    subName: 'Режиссёр-постановщик',
    id: 'Malenko',
    description: 'Заслуженный деятель искусств России, поэт, режиссёр, руководитель Московского театра поэтов, музея Есенина и Всероссийского фестиваля «Филатов Фест», ведущий Первого канала.',
    text: [
      'Продолжу эти строчки без конфет.<br/>' +
      '(Прости, Евгений, ты не виноват)<br/>' +
      '«Поэт в России больше чем поэт,<br/>' +
      'Но меньше чем солдат».<br/>',
      //
      'По струнам бей,<br/>' +
      'стирай нутро до дыр,<br/>' +
      'С царями за свободу<br/>' +
      'пей вино.<br/>' +
      'Весь мир прекрасен!<br/>' +
      'Больше мир, чем мир,<br/>' +
      'Но меньше, чем Россия<br/>' +
      'всё равно.<br/>'
    ],
  },
  {
    isOpen: false,
    name: 'Анастасия Шевченко',
    subName: 'Актриса',
    id: 'Shevchenko',
    description: 'Актриса кино и Московского мюзикл-театра «Монотон».',
    text: [
      'В светлый дом с огнями и пледом,<br/>' +
      'Где сидят все и пьют сладкий чай.<br/>' +
      'Год за годом сменяется снегом...<br/>' +
      'Несмотря ни на что — приезжай!<br/>',
      //
      'Мы вернёмся и нетерпеливо<br/>' +
      'Скажешь : «Ах как я встречи ждал!».<br/>' +
      'Мысль сквозная промчится мимо —<br/>' +
      '«Словно ты и не уезжал»...<br/>'
    ],
  },
  {
    isOpen: false,
    name: 'Роман Сорокин',
    subName: 'Актёр',
    id: 'Sorokin',
    description: 'Актёр, поэт, драматург, артист Московского театра поэтов Влада Маленко, автор двух сборников стихов, текстов песен, пьес и интермедий, звучащих в разных театрах России.',
    text: ['Какую тему не бери, а всё равно придёшь к Донбассу,<br/>' +
    'Поэтому себе не ври — начни писать об этом сразу.<br/>' +
    'Стоит плешивый террикон и терпит солнышко июля.<br/>' +
    'А чуть поодаль - полигон, и от него шальная пуля<br/>' +
    'Летит к искусственной горе, вот-вот в неё уткнётся носом.<br/>' +
    'Я о любви и о добре, о солнышке рыжеволосом,<br/>' +
    'О тёмно-синей вышине, ста градусах по Фаренгейту,<br/>' +
    'О том, как слышно в тишине степного жаворонка флейту.<br/>' +
    'Как хочешь это назови. Пускай я буду зэт-поэтом.<br/>' +
    'Я о добре и о любви. Все помыслы мои об этом.<br/>'],
  },
  {
    isOpen: false,
    name: 'Сергей Шолох',
    subName: 'Актёр',
    id: 'Sholoh',
    description: 'Артист Московского театра поэтов Влада Маленко и театра «У Никитских ворот», автор и исполнитель песен.',
    text: ['У меня вопрос: в каком году началась война? В 2014-м?<br/> Правильно! Двадцать четвертого февраля 2022-го? Тоже верно!<br/> А если я скажу, что лет 500 назад? От Отечественной войны<br/> 1812-го до Крымской и 50 лет не прошло, от Крымской до<br/> Первой мировой — 58 лет, от 1914-го до 1941-го — 27. И так<br/> далее. Это одна и та же война, когда Европа приходит «спасать»<br/> Россию. Они приходят за нашими душами. Потому и<br/> проигрывают.'],
  }
]

export const ArtistsScreen = () => {
  const [artistId, setArtistId] = useState('ZveroboyBand');
  const {isMobile} = useMq();

  return (
    <div id={'nav2'} className={styles.ArtistsScreen}>
      {!isMobile ? (
        <>
          <ArtistsPhoto id={artistId} pages={pages}/>
          <ArtistsListDesktop current={artistId} list={list} onChange={setArtistId}/>
        </>
      ) : <ArtistsListMobile list={list} onChange={setArtistId} pages={pages} />}
    </div>
  );
};
