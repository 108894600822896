import React, {useEffect, useState} from "react";
import styles from './ArtistsFoto.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import birdA from '../../../shared/assets/image/artists/birds/birdA.svg';
import birdB from '../../../shared/assets/image/artists/birds/birdB.svg';
import birdC from '../../../shared/assets/image/artists/birds/birdC.svg';
import cn from 'classnames';
import 'swiper/css/pagination';
import 'swiper/css';

export const ArtistsPhoto = ({id, pages }) => {
  const [obj, setObj] = useState({});
  const [current, setCurrent] = useState('');
  const [prev, setPrev] = useState('');

  useEffect(() => {
    if (current !== '') {
      setPrev(current)
    }
  }, [id])

  useEffect(() => {
    setCurrent(id);
  }, [id])

  useEffect(() => {
    setObj(pages?.find(item => item?.id === id))
  }, [id])

  const objFindFunc = (itemId) => pages?.find(item => item?.id === itemId)

  return (
    <div className={`${styles.ArtistsPhoto} animated`}>
      <div className={styles.ArtistsPhoto__paper} />
      <img className={styles.ArtistsPhoto__bird} src={birdA} alt={"bird"} style={obj?.birdPosition?.a ? obj?.birdPosition?.a : {display: 'none'}}/>
      <img className={styles.ArtistsPhoto__bird} src={birdB} alt={"bird"} style={obj?.birdPosition?.b ? obj?.birdPosition?.b : {display: 'none'}}/>
      <img className={styles.ArtistsPhoto__bird} src={birdC} alt={"bird"} style={obj?.birdPosition?.c ? obj?.birdPosition?.c : {display: 'none'}}/>
      {pages.map(item => {
        if ( item?.imageSrc && typeof item.imageSrc === 'string') {
          return (
            <div className={styles.ArtistsPhoto__imgWrapper} key={`img_photo_${item.id}`}>
              <img className={cn(styles.ArtistsPhoto__img, item.id === current && styles.ArtistsPhoto__img__show, item.id === prev && styles.ArtistsPhoto__img__unmountImg)} src={item.imageSrc} alt={'Photo'}/>
              <img className={cn(styles.ArtistsPhoto__tape, item.id === current && styles.ArtistsPhoto__tape__show, item.id === prev && styles.ArtistsPhoto__unmountTape)} style={item.position} src={item.tape} alt={""}/>
            </div>
          )
        } else if (item?.imageSrc && typeof item.imageSrc === 'object' ) {
          return (
            <div className={styles.ArtistsPhoto__swipperWrapper}>
              <Swiper
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination]}
                className={cn(styles.ArtistsPhoto__swipper, item.id === current && styles.ArtistsPhoto__swipper__show, item.id === prev && styles.ArtistsPhoto__swipper__unmountSwipper)}
              >
                {objFindFunc(item.id).imageSrc?.map((item, index) => (
                  <div key={`zveroboy_Foto_slide_desktop_${item}__${index}`}>
                    <SwiperSlide>
                      <img className={styles.ArtistsPhoto__imgSwaiper} src={item} alt={'zveroboy'}/>
                    </SwiperSlide>
                  </div>
                ))}
              </Swiper>
            </div>
          );
        }
      })}
    </div>
  );
}
