import React, {useEffect, useState} from "react";
import styles from './ArtistsListMobile.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import paperUp from '../../../shared/assets/image/paperUp.svg'
import paperDown from '../../../shared/assets/image/paperDown.svg'
import cn from "classnames";
import 'swiper/css/pagination';
import 'swiper/css';
import {scrollToElm} from "../../../shared/scripts";

export const ArtistsListMobile = ({onChange, list, pages}) => {
  const [itemId, setItemId] = useState('');

  useEffect(() => {
    onChange(itemId);
  }, [itemId])

  function scrollElement(id){
    setTimeout(() => {
      const container = document.getElementById('div');
      const target = document.getElementById(id);
      scrollToElm(container, target, 0.1)
    }, 0);
  }

  const onClick = (id) => {
    scrollElement(id);
    setItemId(id);
  }

  return (
    <div id={'div'} className={styles.ArtistsListMobile}>
      {list.map(item => {
        return (
          <div key={`artistListMobile_wrapper_${item.id}`} id={item.id} className={cn(item.id === itemId && styles.ArtistsListMobile__Wrapper)}>
            {item.id === itemId && <img className={cn(item.id === itemId && styles.ArtistsListMobile__paperUp)} src={paperUp} alt=""/>}
            <div onClick={() => onClick(item.id)} className={cn(styles.ArtistsListMobile__nameWrapper, item.id === itemId && styles.ArtistsListMobile__nameWrapper__onActive)}>
              <p className={cn(styles.ArtistsListMobile__name, item.id === itemId && styles.ArtistsListMobile__name__onActive)}>{item.name}</p>
              <p className={cn(styles.ArtistsListMobile__subName, item.id === itemId && styles.ArtistsListMobile__subName__onActive)}>{item.subName}</p>
            </div>
            {itemId === item.id && (
              <div className={styles.ArtistsListMobile__wrapper}>
                {itemId !== '' && typeof pages?.find(item => item.id === itemId)?.imageSrc === 'string' && <img className={styles.ArtistsListMobile__img} src={pages.find(item => item.id === itemId)?.imageSrc} alt={'Photo'}/>}
                {itemId !== '' && typeof pages?.find(item => item.id === itemId)?.imageSrc === 'object' && (
                  <div className={styles.ArtistsListMobile__swipperWrapper}>
                    <Swiper
                      spaceBetween={40}
                      pagination={{
                        clickable: true,
                      }}
                      navigation={true}
                      modules={[Pagination]}
                      className={styles.ArtistsListMobile__swipper}
                    >
                      {pages.find(item => item.id === itemId)?.imageSrc?.map((item, index) => (
                        <SwiperSlide key={`zveroboy_slide_${index}`}>
                          <img className={styles.ArtistsListMobile__imgSwiper} src={item} alt={'zveroboy'}/>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                )}
                <div dangerouslySetInnerHTML={{__html: item.description}} className={styles.ArtistsListMobile__description}/>
                <div className={styles.ArtistsListMobile__textWrapper}>
                  {item.text.map( (textItem, index) => (
                    <div className={styles.ArtistsListMobile__textItemWrapper} key={`textItem-${item.id}-${index}`}>
                      <div style={{height: '50%'}} dangerouslySetInnerHTML={{__html: textItem}} className={styles.ArtistsListMobile__textItem}/>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {item.id === itemId && <img className={cn(item.id === itemId && styles.ArtistsListMobile__paperDown)} src={paperDown} alt=""/>}
          </div>
        );
      })}
    </div>
  );
};
