import React from "react";
import styles from "./MainScreen.module.scss";
// import { ReactComponent as Clouds } from "../../img/сlouds.svg"
// import { ReactComponent as Red } from "../../img/Red.svg"
import { ReactComponent as Logo } from "../../img/Logo.svg"
import { ReactComponent as TheatreLogo } from "../../img/logo catra cream.svg"
import { ReactComponent as TheatreLogoSmall } from "../../img/theatreSmall.svg"
import { ReactComponent as Age } from "../../img/12+.svg"
import { ReactComponent as AgeGray } from "../../img/12grey.svg"

import { ReactComponent as World } from "../../img/bx-world.svg"
import { ReactComponent as Telegram } from "../../img/bxl-telegram.svg"
import { ReactComponent as Vk } from "../../img/bxl-vk.svg"
import { ReactComponent as Youtube } from "../../img/bxl-youtube.svg"
import { ReactComponent as Envelope } from "../../img/bxs-envelope.svg"
import useMq from "../../shared/hooks/useMq";

export const MainScreen = ({query}) => {
  const {isMobile, isTablet} = useMq();
  const [isActiveBtn, setIsActiveBtn] = React.useState(false)
  const clickBurger = () => {
    setIsActiveBtn(!isActiveBtn)
  }

  return <div className={styles.MainScreen}>
    <header>
      <div className={styles.MainHeader}>
        <div className={styles.MainHeader__Logo}>
          {
            isTablet && (
            <a className={styles.PerformanceScreen__link} target={'_blank'} href={`https://iframeab-pre3794.intickets.ru/seance/13952077/?${query}`}>
              {/*global _tmr */}
              <button onClick={() => _tmr.push({ type: 'reachGoal', id: 3400462, value: 1, goal: 'lead'})} value="Заказать" className={styles.MainHeader__BtnLogo}>Купить билет</button>
            </a>
            )
          }
          {!isTablet && <Logo/>}
        </div>
        <div>
          <div
            className={
              `${styles.MainHeader__wrapperBurger} ${isActiveBtn ? styles.MainHeader__wrapperBurger_active : ''}`
            }
            onClick={clickBurger}
          >
            <div className={styles.MainHeader__burger}>
              <span></span>
            </div>
          </div>
          <div className={
              `${styles.MainHeader__Nav} ${isActiveBtn ? styles.active : ''}`
            }>
            <nav>
              <a href="#nav1" onClick={() => setIsActiveBtn(false )}>О мероприятии</a>
              <a href="#nav2" onClick={() => setIsActiveBtn(false )}>Участники</a>
              <a href="#nav3" onClick={() => setIsActiveBtn(false )}>Место</a>
              <a href="#nav4" onClick={() => setIsActiveBtn(false )}>Видео</a>
            </nav>
            <div className={styles.MainHeader__NavFooter}>
              <a className={styles.MainHeader__NavBtn} target={'_blank'} href={`https://iframeab-pre3794.intickets.ru/seance/13952077/?${query}`}>
                <button className={styles.MainHeader__BtnBurger}>Купить билет</button>
              </a>
              <div className={styles.MainHeader__Links}>
                <a target={'_blank'} href="https://www.zveroboi.ru/"><World /></a>
                <a href="mailto:zveroboi2020@yandex.ru"><Envelope /></a>
                <a target={'_blank'} href="https://vk.com/zveroboi_official"><Vk /></a>
                <a target={'_blank'} href="https://t.me/ZVEROROK"><Telegram /></a>
                <a target={'_blank'} href="https://www.youtube.com/channel/UCq7dvQC9arfGaNF65e_-wmg/videos"><Youtube /></a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.MainHeader__Action}>
          <a className={styles.PerformanceScreen__link} target={'_blank'} href={`https://iframeab-pre3794.intickets.ru/seance/13952077/?${query}`}>
            {/*global _tmr */}
            <button onClick={() => _tmr.push({ type: 'reachGoal', id: 3400462, value: 1, goal: 'lead'})} value="Заказать" className={styles.MainHeader__Btn}>Купить билет</button>
          </a>
        </div>
      </div>
    </header>
    {/* <div className={styles.MainScreen__WrapperSvg}>
      <Clouds className={styles.MainScreen__SvgClouds}/>
      <Red className={styles.MainScreen__SvgRed}/>
    </div> */}
    <div className={styles.MainScreen__Wrapper}>
      <div className={styles.MainScreen__theatreLogo}>
        <a target={'_blank'} href={'https://teatrarmii.ru/'}>
          <TheatreLogo className={styles.MainScreen__theatreLogoBig}/>
          <TheatreLogoSmall className={styles.MainScreen__theatreLogoSmall}/>
        </a>
      </div>
      <div className={`${styles.MainScreen__time} animated`}>
        <div>18 ноября</div>
        <div>18:00</div>
      </div>
      <div className={styles.MainScreen__ageLogo}>
        <Age className={styles.MainScreen__ageLogoWhite}/>
        <AgeGray className={styles.MainScreen__ageLogoGray}/>
      </div>
    </div>
  </div>
};
