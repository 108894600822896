import React from "react";
import styles from "./FooterScreen.module.scss";
import { ReactComponent as Logo } from "../../img/whiteLogo.svg"
import { ReactComponent as World } from "../../img/bx-world.svg"
import { ReactComponent as Telegram } from "../../img/bxl-telegram.svg"
import { ReactComponent as Vk } from "../../img/bxl-vk.svg"
import { ReactComponent as Youtube } from "../../img/bxl-youtube.svg"
import { ReactComponent as Envelope } from "../../img/bxs-envelope.svg"
import { ReactComponent as Dust } from "../../img/logo pfki dust.svg"
import { ReactComponent as Theatre } from "../../img/theatre.svg"
import useMq from "../../shared/hooks/useMq";
import cn from "classnames";

export const FooterScreen = () => {
  const {isMobile} = useMq();

  return <div className={styles.FooterScreen}>
    <div className={styles.FooterScreen__Contacts}>
      <div className={styles.FooterScreen__ContactsLogo}>
        <Logo/>
      </div>
      <div className={styles.FooterScreen__ContactsItems}>
        <a target={'_blank'} href="https://www.zveroboi.ru/"><World /></a>
        <a href="mailto:zveroboi2020@yandex.ru"><Envelope /></a>
        <a target={'_blank'} href="https://vk.com/zveroboi_official"><Vk /></a>
        <a target={'_blank'} href="https://t.me/ZVEROROK"><Telegram /></a>
        <a target={'_blank'} href="https://www.youtube.com/channel/UCq7dvQC9arfGaNF65e_-wmg/videos"><Youtube /></a>
      </div>
    </div>
    <div className={styles.FooterScreen__Partners}>
      <div className={styles.FooterScreen__PartnersLeft}>
        <a target={'_blank'} href={'http://xn--80aeeqaabljrdbg6a3ahhcl4ay9hsa.xn--p1ai/'}>
          <Dust className={styles.FooterScreen__pfki}/>
        </a>
      </div>
      <div className={styles.FooterScreen__PartnersRight}>
        <a target={'_blank'} href={'https://teatrarmii.ru/'}>
          <button className={styles.FooterScreen__Btn}>
            <div>{isMobile ? 'Сайт театра' : 'Посетить сайт театра'}</div>
            <Theatre />
          </button>
        </a>
      </div>
    </div>
    <div className={styles.FooterScreen__partners}>
      <p className={styles.FooterScreen__partners__tittle}>Информационные парнеры</p>
      <div className={styles.FooterScreen__partners__wrapper}>
        <a href={'https://www.nashe.ru/'} target={'_blank'}>
          <button>
            <div className={cn(styles.FooterScreen__partners__btn, styles.FooterScreen__partners__btn_nasheRadio)} />
          </button>
        </a>
        <a href={'https://t.me/izolentalive'} target={'_blank'}>
          <button>
            <div className={cn(styles.FooterScreen__partners__btn, styles.FooterScreen__partners__btn_izolenta)} />
          </button>
        </a>
      </div>
    </div>
    <div className={styles.FooterScreen__Requisites}>
      <div className={styles.FooterScreen__RequisitesLeft}>
        <p>ИП Терентьева</p>
        <p>ИНН 772573234800</p>
        <p>ОГРНИП 316774600433949</p>
      </div>
      <div className={styles.FooterScreen__RequisitesCenter}>
        <p>2023 год</p>
      </div>
      <div className={styles.FooterScreen__RequisitesRight}>
        <p>Все права защищены ©</p>
      </div>
    </div>
    <span id={'bottom'}/>
  </div>
};
