import React from "react";
import fondLogo from '../../shared/assets/image/fondLogo.svg';
import styles from './PerformanceScreen.module.scss';


export const PerformanceScreen = ({query}) => {

  return (
    <div id={"nav1"} className={`${styles.PerformanceScreen} animated`}>
      <div className={styles.PerformanceScreen__wrapper}>
        <div>
          <h3>Музыкально-поэтический спектакль</h3>
        </div>
        <div className={styles.PerformanceScreen__text}>
          <div>
            <p>
              Русский рок, русская поэзия, русский театр: «Путь к Победе» — это концерт-спектакль на стыке трёх видов искусства, поставленный Владом Маленко.
              <br/>
              <br/>
              В «Пути к Победе» энергичные песни «Зверобоя» переплетаются с военной документальной хроникой событий в ЛДНР и хлёсткими стихами Влада Маленко и луганской поэтессы Елены Заславской.
              <br/>
              <br/>
              Музыка борьбы и стойкости, поэзия схватки современных цивилизаций, драматургия перелома мировой истории — на премьере 18 ноября. Ждём вас!
            </p>
            <p className={styles.PerformanceScreen__time}>Продолжительность спектакля — 90 минут</p>
          </div>
          <div className={styles.PerformanceScreen__btn}>
            {/*global _tmr */}
          <button style={{display: 'hidden'}} onClick={() => _tmr.push({ type: 'reachGoal', id: 3400462, value: 1, goal: 'lead'})} value="Заказать">
            <a rel="noreferrer" target={'_blank'} href={`https://iframeab-pre3794.intickets.ru/seance/13952077/?${query}`}>
              <button>Купить билет</button>
            </a>
          </button>
            <a rel="noreferrer" target={'_blank'} href={'http://xn--80aeeqaabljrdbg6a3ahhcl4ay9hsa.xn--p1ai/'}>
              <img className={styles.PerformanceScreen__imgFond} src={fondLogo} alt={"Фонд"} />
            </a>
          </div>
        </div>

      </div>
    </div>
  );
}
