export function scrollToElm(container, elm, duration = 2){
  let pos = getRelativePos(elm);
  console.log(container, elm);
  scrollTo( container, pos.top , duration);  // duration in seconds
}

function getRelativePos(elm){
    let pPos = elm.parentNode.getBoundingClientRect(); // parent pos
    let cPos = elm.getBoundingClientRect(); // target pos
    let pos = {};

    pos.top    = cPos.top    - pPos.top + elm.parentNode.scrollTop;
    pos.right  = cPos.right  - pPos.right;
    pos.bottom = cPos.bottom - pPos.bottom;
    pos.left   = cPos.left   - pPos.left;

  return pos;
}

function scrollTo(element, to, duration, onDone) {
  element.scrollIntoView()
  let start = element.scrollTop,
    change = to - start,
    startTime = performance.now(),
    val, now, elapsed, t;

  function animateScroll(){
    now = performance.now();
    elapsed = (now - startTime)/1000;
    t = (elapsed/duration);

    element.scrollTop = start + change * easeInOutQuad(t);

    if( t < 1 )
      window.requestAnimationFrame(animateScroll);
    else
      onDone && onDone();
  };

  animateScroll();
}

function easeInOutQuad(t){ return t<.5 ? 2*t*t : -1+(4-2*t)*t };
